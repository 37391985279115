<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-27 17:01:13
 * @LastEditTime: 2019-11-25 19:44:24
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="WastageSummary">
    <page-top :title="$t('aboutus.44863D@Report')">
      <span style="font-size:26px;" class="fa fa-list-alt"></span>
    </page-top>
    <el-tabs type="border-card">
      <el-tab-pane
        :label="$t('aboutus.8AE12E@Income_breakdown')"
        style="height:70vh;overflow-y:auto;overflow-x:hidden;"
      >
        <AccountingReport :restaurantList="restaurantList"></AccountingReport>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('backend_dashboard.C8866F@order')"
        style="height:70vh;overflow-y:auto;overflow-x:hidden;"
      >
        <Order :restaurantList="restaurantList"></Order>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('aboutus.41856B@supplier_block1')"
        style="height:70vh;overflow-y:auto;overflow-x:hidden;"
      >
        <UnpaidOrder :restaurantList="restaurantList"></UnpaidOrder>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import pageTop from "../../../components/PageTop/PageTop";
import KtButton from "../../../components/ktButton/KtButton";
import Order from "./components/Order";
import UnpaidOrder from "./components/UnpaidOrder";
import AccountingReport from "./components/AccountingReport";
export default {
  data() {
    return {
      restaurantList: []
    };
  },
  components: {
    pageTop,
    KtButton,
    Order,
    UnpaidOrder,
    AccountingReport
  },
  computed: {},
  filters: {},
  watch: {},
  created() {
    this.restaurantSelector();
  },
  methods: {
    restaurantSelector() {
      this.$http.restaurantSelector().then(res => {
        this.restaurantList = res.data;
      });
    }
  }
};
</script>
<style lang="scss">
.WastageSummary {
  .box-card {
    display: flex;

    .choose-item {
      display: flex;
      align-items: center;

      .item-one {
        margin-right: 20px;
        max-width: 18%;

        .item-title {
          padding-right: 20px;
        }
      }

      .item-one.sel {
        width: 12%;
      }

      .choose-btn {
        width: 80px;
        height: 32px;
        border: none;
        background-image: linear-gradient(
          -90deg,
          #ff9805 0%,
          #f2cd33 100%
        ) !important;
        color: #fff;
        margin-left: 20px;
      }
    }

    .pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  .dialog-title {
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;
    display: block;
    line-height: 16px;
    font-size: 16px;
    color: #333333;
  }

  .v-modal {
    position: absolute;
    z-index: 9 !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: #fff;
  }

  .el-dialog__wrapper {
    position: absolute;
    z-index: 99 !important;
  }

  .notice {
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    color: #999999;
  }

  .input {
    width: 80%;
  }
  .ReportForm {
    width: 350px;
    margin: 50px auto;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }
}
</style>