<!--  -->
<template>
  <div class="AccountingReport">
    <section class="justify-between">
      <el-form :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-date-picker
            v-model="search.datetime"
            type="daterange"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            @change="orderChange"
            range-separator="~"
            :start-placeholder="$t('placeholder.524620@start_time')"
            :end-placeholder="$t('placeholder.ABB7CF@end_time')"
            style="width:220px"
          ></el-date-picker>
        </el-form-item>
        <!-- 餐厅 -->
        <el-form-item>
          <el-select
            filterable
            v-model="search.restaurant_id"
            clearable
            :placeholder="$t('aboutus.033E98@Please_Select_Restaurant')"
            style="width:250px"
            @change="orderChange"
          >
            <el-option
              v-for="item in restaurantList"
              :key="item.company_id"
              :label="item.company_name"
              :value="item.company_id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        size="small"
        @click="exportAccountantPDF"
      >{{$t('aboutus.2AECD0@Export_Order')}}</el-button>
    </section>

    <el-table
      border
      class="mt20"
      :row-class-name="$tableRowClassName"
      :data="tableData"
      :highlight-current-row="true"
      v-loading="loading"
      :element-loading-text="$t('public.4ABAB1@loading')"
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column prop="date" :label="$t('aboutus.381E69@Date')"></el-table-column>
      <el-table-column prop="order_no" :label="$t('placeholder.5C894A@order_code')"></el-table-column>
      <el-table-column prop="restaurant_name" :label="$t('aboutus.E8221A@restaurant_name')"></el-table-column>
      <el-table-column prop="amount" :label="$t('aboutus.FE0DEB@Amount')"></el-table-column>
      <template slot="append" v-if="total_amount[1]>0">
        <div class="total_amount">
          {{$t('aboutus.CBD374@total_price')}}&nbsp;:
          <span>{{total_amount}}</span>
        </div>
      </template>
    </el-table>
    <section style="margin-top:20px;display:flex;justify-content:flex-end;">
      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        :page-sizes="[5, 10, 15]"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </section>
    <section class="ReportForm1" v-show="ReportFormShow">
      <el-form ref="relueForm" :rules="rules" :model="search" style="padding: 60px">
        <el-form-item prop="datetime">
          <el-date-picker
            v-model="search.datetime"
            type="daterange"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="~"
            :start-placeholder="$t('placeholder.524620@start_time')"
            :end-placeholder="$t('placeholder.ABB7CF@end_time')"
            style="width:220px"
          ></el-date-picker>
        </el-form-item>
        <!-- 门店 -->
        <el-form-item prop="restaurant_id">
          <el-select
            filterable
            v-model="search.restaurant_id"
            clearable
            :placeholder="$t('supplier_order.066E3C@shop')"
            style="width:220px"
          >
            <el-option
              v-for="item in restaurantList"
              :key="item.company_id"
              :label="item.company_name"
              :value="item.company_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="justify-around">
          <el-button
            size="small"
            type="primary"
            @click="getAccountantList('relueForm')"
          >{{$t('public.3B45A2@confirm')}}</el-button>
        </el-form-item>
      </el-form>
    </section>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import引入的组件需要注入到对象中才能使用
  name: "AccountingReport",
  components: {},
  props: {
    restaurantList: {
      type: Array,
      default: []
    }
  },
  data () {
    //这里存放数据
    return {
      search: {
        datetime: "",
        restaurant_id: ""
      },

      rules: {
        datetime: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: 'change' }
      },
      tableData: [],
      loading: false,
      tableData: [],
      ReportFormShow: true,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      total_amount: "",
      pickerDisabled: {
        disabledDate: time => {
           let date = new Date();
          let str =
            date.getFullYear() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getDate();
          return (
            Date.parse(time) / 1000 >=
            Date.parse(str) / 1000
          );
        }
      }
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //   获取会计报告
    getAccountantList (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ReportFormShow = false;
          this.loading = true;
          const params = {
            size: this.pageSize,
            page: this.currentPage,
            restaurant_id: this.search.restaurant_id,
            start_time: this.search.datetime ? this.search.datetime[0] / 1000 : "",
            end_time: this.search.datetime ? this.search.datetime[1] / 1000 : ""
          };
          this.$http.getAccountantList(params).then(res => {
            this.loading = false;
            this.tableData = res.data.list;
            this.total = res.data.sum;
            this.total_amount = res.data.total_amount;
          });
        } else {
          return false;
        }
      });

    },
    // 导出账单确认
    exportAccountantPDF () {
      if (Boolean(this.search.datetime)) {
        this.$router.push(
          `/app/Accounting/ExportReport/${this.search.datetime[0] / 1000}/${this
            .search.datetime[1] / 1000}/${this.search.restaurant_id || 0}
          }`
        );
      } else {
        this.$message.warning(this.$t("aboutus.A9AC5D@Please_Select"));
      }
    },
    // 筛选
    orderChange (val) {
      this.currentPage = 1;
      this.getAccountantList();
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.getAccountantList();
    },
    handleCurrentChange (val) {
      this.currentPage = val;
      this.getAccountantList();
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { } //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss'>
//@import url(); 引入公共css类
.AccountingReport {
  position: relative;
  .total_amount {
    padding: 10px 30px;
    text-align: right;
    font-size: 14px;
    span {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #f24040;
    }
  }

  .dialog-title {
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;
    display: block;
    line-height: 16px;
    font-size: 16px;
    color: #333333;
  }

  .v-modal {
    position: absolute;
    z-index: 9 !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: #fff;
  }

  .el-dialog__wrapper {
    position: absolute;
    z-index: 99 !important;
  }
  .ReportForm1 {
    position: absolute;
    z-index: 999;
    top: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
    .el-form {
      width: 350px;
      margin: 50px auto;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
