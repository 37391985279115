<!--  -->
<template>
  <div class="UnpaidOrder">
    <section style="display:flex;justify-content: space-between;">
      <el-form :inline="true" :model="search2" class="demo-form-inline">
        <el-form-item>
          <el-date-picker
            v-model="search2.datetime"
            type="daterange"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            @change="UnpaidChange"
            range-separator="~"
            :start-placeholder="$t('placeholder.524620@start_time')"
            :end-placeholder="$t('placeholder.ABB7CF@end_time')"
            style="width:220px"
          ></el-date-picker>
        </el-form-item>
        <!-- 餐厅 -->
        <el-form-item>
          <el-select
            filterable
            v-model="search2.restaurant_id"
            @change="UnpaidChange"
            clearable
            :placeholder="$t('aboutus.033E98@Please_Select_Restaurant')"
          >
            <el-option
              v-for="item in restaurantList"
              :key="item.company_id"
              :label="item.company_name"
              :value="item.company_id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <section>
        <el-button
          type="primary"
          size="small"
          @click="exportNotPaidOrderExcel"
        >{{$t('aboutus.2AECD0@Export_Order')}}</el-button>
      </section>
    </section>
    <!-- 表格 -->
    <el-table
      class="mt20"
      :row-class-name="$tableRowClassName"
      :data="tableData2"
      :highlight-current-row="true"
      v-loading="loading2"
      :element-loading-text="$t('public.4ABAB1@loading')"
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column prop="order_no" :label="$t('supplier_order.46C89C@order_reference')"></el-table-column>
      <el-table-column
        prop="restaurant_name"
        :label="$t('backend_authrity_authrityList.C90CD7@restaurant_tab')"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="buyer_shop_name" :label="$t('orderDetail.2D64A4@store_name')"></el-table-column>

      <el-table-column prop="final_price" :label="$t('public.C82510@order_amount')">
        <template scope="scope">
          <span v-if="scope.row.final_price">{{scope.row.final_price}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="order_status" :label="$t('supplier_order.E5F50A@order_status')">
        <template scope="scope">
          <span :style="scope.row.order_color|sizeColor">{{scope.row.order_status}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="pay_status" :label="$t('public.180B5F@Payment_status')">
        <template scope="scope">
          <section :style="scope.row.pay_colour|sizeColor">
            <div>{{scope.row.pay_status}}</div>
            <div v-show="scope.row.payment_due">({{scope.row.payment_due}})</div>
          </section>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" :label="$t('public.D17BCA@created_time')"></el-table-column>
    </el-table>
    <section style="margin-top:20px;display:flex;justify-content:flex-end;">
      <el-pagination
        class="pagination"
        @size-change="handleSizeChange2"
        :page-sizes="[5, 10, 15]"
        :page-size="pageSize2"
        @current-change="handleCurrentChange2"
        :current-page="currentPage2"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total2"
      ></el-pagination>
    </section>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "UnpaidOrder",
  components: {},
  props: {
    restaurantList: {
      type: Array,
      default: []
    }
  },
  data() {
    //这里存放数据
    return {
      search2: {
        datetime: "",
        restaurant_id: ""
      },
      loading2: false,
      tableData2: [],
      currentPage2: 1,
      pageSize2: 10,
      total2: 0
    };
  },
  filters: {
    sizeColor: function(value) {
      return `color:${
        value == 1
          ? "green"
          : value == 2
          ? "#f2cd33"
          : value == 3
          ? "#f24040"
          : ""
      }`;
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 获取未付款订单
    getNotPaidOrderList() {
      this.loading2 = true;
      let params = {
        size: this.pageSize2,
        page: this.currentPage2,
        restaurant_id: this.search2.restaurant_id,
        start_time: this.search2.datetime
          ? this.search2.datetime[0] / 1000
          : "",
        end_time: this.search2.datetime ? this.search2.datetime[1] / 1000 : ""
      };
      this.$http.getNotPaidOrderList(params).then(res => {
        this.loading2 = false;
        this.tableData2 = res.data.list;
        this.total2 = res.data.sum;
      });
    },
    //导出未付款订单
    exportNotPaidOrderExcel() {
      let params = {
        restaurant_id: this.search2.restaurant_id,
        start_time: this.search2.datetime
          ? this.search2.datetime[0] / 1000
          : "",
        end_time: this.search2.datetime ? this.search2.datetime[1] / 1000 : ""
      };
      this.$http.exportNotPaidOrderExcel(params).then(res => {
        this.downloadFile(res);
      });
    },
    // 未付款筛选
    UnpaidChange() {
      this.currentPage2 = 1;
      this.getNotPaidOrderList();
    },
    handleSizeChange2(val) {
      this.pageSize2 = val;
      this.getNotPaidOrderList();
    },
    handleCurrentChange2(val) {
      this.currentPage2 = val;
      this.getNotPaidOrderList();
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getNotPaidOrderList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
</style>
